.App {
  text-align: center;
}

span {
  font-weight: 900;
}

#email:hover {
  color: white;
}

#email {
  text-decoration: none;
  font-weight: 900;
  color: black;
}

.body {
  display: flex;
  justify-content: center;
  align-items: top;
  overflow-y: auto; 
  overflow-x: hidden;
}

.left {
  flex: 3;
  text-align: left;
  overflow-y: scroll;
  margin: 10px;
  height: 60vh;
  text-align: center;
}

.right {
  flex: 0.5;
  overflow-y: auto;
  text-align: left;
  margin: 10px;
  height: 60vh;
}

.App-header {
  background-color: #282c34;
  min-height: 28vh;
  width: 100vw;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.pics {
  width: 250px;
  height: 300px;
  border: 4px solid rgba(128, 128, 128, 0.8);
  box-sizing: border-box;
}

.projectinfo {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  height: 320px;
  width: 300px;
  text-align: left;
}

#description1, .flip, #description2, #description3, #description4 {
  background-color: gray;
  width: 250px;
  border: 4px solid rgba(128, 128, 128, 0.8);
  box-sizing: border-box;
}

.p4 {
  height: 75px;
}
.p4tech {
  height: 115px;
}

#description1, #description2, #description3, #description4 {
  display: none;
  padding: 2px;
  background-color: rgb(192, 189, 189);
}

.flip {
  font-family: 'Anton', sans-serif;
}

.flip:hover {
  color: white;
  background-color: #282c34;
}

.projectlink:hover {
  color: white;
  background-color: #282c34;
}

.links {
  text-decoration: none;
  font-family: 'Anton', sans-serif;
  margin: 7px;
  padding: 7px;
  color: white;
}

.link {
  text-decoration: none;
  font-family: 'Anton', sans-serif;
  margin: 5px;
  padding: 5px;
}

.links:hover {
  color: #282c34;
}

.projectlinksection {
  text-align: center;
  width: 250px;
}

.projectlink {
  text-decoration: none;
  color: black;
  font-family: 'Anton', sans-serif;
  letter-spacing: 1px;
  font-size: 13.5px;
  margin-left: 5px;
  margin-top: 2px;
  width: 40px;
  height: 10px;
  border: 4px solid gray;
}

.intro {
  /* border: 4px solid white; */
  margin-top: 0;
  width: 100vw;
  margin: 0 auto;
  background-image: url('/pic.jpg');
  /* background-position: center;  */
  background-repeat: no-repeat; 
  background-size: cover;
}

.footer {
  height: 2vh;
  width: 100vw;
  font-size: 2px;
}

@media only screen and (max-width: 600px) {
  .links {
    font-size: 9px;
  }

  .right {
    text-align: left;
    flex: 0.7;
  }

  .icons {
    width: 20px;
    height: 20px;
  }

  .App-header {
    height: 20vh;
  }

  .footer {
    height: 1vh;
    font-size: 3px;
  }

  .projectinfo {
    display: inline-block;
    margin: 2px;
    padding: 2px;
    height: 220px;
    width: 170px;
    text-align: center;
  }

  .pics {
    width: 170px;
    height: 200px;
    border: 4px solid gray;
  }

  #description1, .flip, #description2, #description3, #description4 {
    background-color: gray;
    width: 170px;
    border: 4px solid gray;
  }

  .p4, .p4tech {
    font-size: 12px;
  }

  .p4 {
    height: 60px;
  }

  .p4tech {
    height: 90px;
  }

  .projectlinksection {
    width: 170px;
  }
}



